<template>
  <div class="social bg-white">
    <b-container class="p-0">
      <a
        href="https://www.instagram.com/ode_toronto/?utm_medium=copy_link"
        target="_blank"
        class="lg-text"
        >@ODE_TORONTO</a
      >
      <b-row class="pt-4">
        <b-col cols="6" md="3" class="p-1">
          <a
            href="https://www.instagram.com/reel/Ct4CJB6NAh-/?utm_source=ig_web_copy_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/home/InstaFeed1.jpg"
              alt="Ode Instagram"
              class="img-fluid d-block w-100 image-hover"
            />
          </a>
        </b-col>
        <b-col cols="6" md="3" class="p-1">
          <a
            href="https://www.instagram.com/p/CumA-KKOG2q/?utm_source=ig_web_copy_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/home/InstaFeed2.jpg"
              alt="Ode Instagram"
              class="img-fluid d-block w-100 image-hover"
            />
          </a>
        </b-col>
        <b-col cols="6" md="3" class="p-1">
          <a
            href="
https://www.instagram.com/p/Ch7jzt5g7nA/?utm_source=ig_web_copy_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/home/InstaFeed3.jpg"
              alt="Ode Instagram"
              class="img-fluid d-block w-100 image-hover"
            />
          </a>
        </b-col>
        <b-col cols="6" md="3" class="p-1">
          <a
            href="https://www.instagram.com/p/CxiAEtCg_nC/?utm_source=ig_web_copy_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/home/InstaFeed4.jpg"
              alt="Ode Instagram"
              class="img-fluid d-block w-100 image-hover"
            />
          </a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh();
    setTimeout(() => {}, 500);
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.social {
  padding: 50px 0px 100px 0px;
  @media screen and (max-width: 1176px) {
    padding: 40px 40px 50px 40px;
  }
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: $m-black;
    text-decoration: none;
  }
  .image-hover {
    transition: opacity 0.3s;
  }

  .image-hover:hover {
    opacity: 0.7;
  }
}
</style>
